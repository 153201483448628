import axios from 'axios'
import JwtService from './jwt.service'

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init () {
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
  },

  /**
     * Set the default HTTP request headers
     */
  async setHeader () {
    let token = await JwtService.getToken()
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  },

  query (resource, params) {
    return axios.get(resource, params)
  },

  /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
  get (resource, slug = '') {
    return axios.get(`${resource}/${slug}`)
  },

  /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
  post (resource, params, contentType = 'application/json') {
    return axios.post(`${resource}`, params, {
      headers: {
        'Content-Type': contentType
      }
    })
  }
}

export default ApiService
