import Vue from 'vue'
import App from './App'
import router from './router'
import ApiService from './services/api.service'

import BootstrapVue from 'bootstrap-vue'

import globals from './globals'
import Popper from 'popper.js'
import ImageUploader from 'vue-image-upload-resize'

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(ImageUploader)

// Global RTL flag
Vue.mixin({
  data: globals
})

ApiService.init()

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
