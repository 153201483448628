import ApiService from './api.service'
import JwtService from './jwt.service'
import qs from 'qs'

const login = async (credentials) => {
  return new Promise(resolve => {
    ApiService.post('token', qs.stringify(credentials))
      .then(({ data, status }) => {
        if (status === 200) {
          JwtService.saveToken(data.access_token)
          resolve({ status: true, message: 'Access granted.' })
        }
      })
      .catch(({ response }) => {
        let message = 'Error'

        if (!response) {
          message = "Can't connect to server."
          resolve({ status: false, message: message })
        } else {
          if (response.status === 400) { message = 'Invalid username or password.' }
          if (response.status === 500) { message = 'Internal Server Error.' }
          if (response.status === 0 || response.status === 502 || response.status === 404) { message = "Can't connect to server." }

          resolve({ status: false, message: message })
        }
      })
  })
}

const logout = async () => {
  return new Promise(resolve => {
    JwtService.destroyToken()
    resolve({ status: true, message: 'Logged out.' })
  })
}

const changePassword = async (credentials) => {
  return new Promise(resolve => {
    ApiService.post('api/account/changePassword', credentials).then(({ status }) => {
      if (status === 200) {
        resolve({ status: true, message: 'success' })
      }
    }).catch(({ response }) => { // client error handle
      console.log(response)

      let error = 'Error'

      if (!response) {
        error = "Can't connect to server."
        resolve({ status: false, message: 'Error', error })
      } else {
        if (response.status === 400) {
          error = response.data.ModelState['']
        }
        if (response.status === 500) { error = 'Internal Server Error.' }
        if (response.status === 0 || response.status === 502 || response.status === 404) { error = "Can't connect to server." }

        resolve({ status: false, statusCode: response.status, message: 'Error', errors: [error] })
      }
      // resolve({ status: false, message: 'Error', errors: [response.statusText] })
    })
  })
}

const verify = async () => {
  return new Promise(resolve => {
    ApiService.setHeader().then(() => {
      ApiService.get('api/account/verify').then(({ status }) => {
        console.log(ApiService)
        if (status === 200) {
          resolve({ status: true, message: 'Access granted' })
        }
      })
        .catch(() => {
          resolve({ status: false, message: 'Invalid token. Access denied!' })
        })
    })
  })
}

const register = async (registerData) => {
  return new Promise(resolve => {
    ApiService.post('api/account/register', registerData).then(({ status }) => {
      if (status === 200) {
        resolve({ status: true, message: 'Account Created' })
      }
    })
      .catch(({ response }) => {
        let message = 'Error'
        let errors = {
          others: [],
          usernameErrors: [],
          emailErrors: [],
          passwordErrors: [],
          confirmPasswordErrors: []
        }
        if (response.status === 400) {
          message = 'The request is invalid.'
          errors.others = response.data.ModelState.errors ? response.data.ModelState.errors : []
          errors.usernameErrors = response.data.ModelState['model.UserName'] ? response.data.ModelState['model.UserName'] : []
          errors.emailErrors = response.data.ModelState['model.Email'] ? response.data.ModelState['model.Email'] : []
          errors.passwordErrors = response.data.ModelState['model.Password'] ? response.data.ModelState['model.Password'] : []
          errors.confirmPasswordErrors = response.data.ModelState['model.ConfirmPassword'] ? response.data.ModelState['model.ConfirmPassword'] : []
        }
        if (response.status === 500) { message = 'Internal Server Error.' }
        if (response.status === 0 || response.status === 502 || response.status === 404) { message = "Can't connect to server." }

        resolve({ status: false, message, errors })
      })
  })
}

export default {
  login,
  logout,
  changePassword,
  verify,
  register
}
