<template>
  <sidenav :orientation="orientation" :class="curClasses">

    <div class="app-brand demo" v-if="orientation !== 'horizontal'">
      <span class="app-brand-logo demo bg-primary">
        <img :src="`${publicUrl}img/logo-white.png`" alt="VTEDCO">
      </span>
      <router-link to="/" class="app-brand-text demo sidenav-text font-weight-normal ml-2">VTEDCO</router-link>
      <a href="javascript:void(0)" class="layout-sidenav-toggle sidenav-link text-large ml-auto" @click="toggleSidenav()">
        <i class="ion ion-md-menu align-middle"></i>
      </a>
    </div>
    <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>

    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">

      <sidenav-router-link icon="ion ion-ios-speedometer" to="/" :exact="true">Dashboard</sidenav-router-link>
      <sidenav-menu icon="ion ion-md-speedometer" :active="isMenuActive('/nha-tuyen-dung')" :open="isMenuOpen('/nha-tuyen-dung')">
        <template slot="link-text">
          Nhà tuyển dụng
        </template>
        <sidenav-router-link to="/nha-tuyen-dung" :exact="true">Danh sách</sidenav-router-link>
        <sidenav-router-link to="/nha-tuyen-dung-noi-bat" :exact="true">Danh sách nổi bật</sidenav-router-link>
      </sidenav-menu>
      <sidenav-menu icon="ion ion-ios-megaphone" :active="isMenuActive('/tin-tuyen-dung')" :open="isMenuOpen('/tin-tuyen-dung')">
        <template slot="link-text">
          Tin tuyển dụng
        </template>
        <sidenav-router-link to="/tin-tuyen-dung" :exact="true">Danh sách</sidenav-router-link>
        <sidenav-router-link to="/tin-tuyen-dung/tin-tot-nhat" :exact="true">Tin tốt nhất</sidenav-router-link>
        <sidenav-router-link to="/tin-tuyen-dung/tin-hap-dan" :exact="true">Tin hấp dẫn</sidenav-router-link>
      </sidenav-menu>
      <sidenav-menu icon="ion ion-ios-people" :active="isMenuActive('/ung-vien')" :open="isMenuOpen('/ung-vien')">
        <template slot="link-text">
          Ứng viên
        </template>
        <sidenav-router-link to="/ung-vien" :exact="true">Danh sách</sidenav-router-link>
        <sidenav-router-link to="/ung-vien/them-ung-vien" :exact="true">Thêm ứng viên</sidenav-router-link>
      </sidenav-menu>
      <sidenav-menu icon="ion ion-md-speedometer" :active="isMenuActive('/tin-tuc')" :open="isMenuOpen('/tin-tuc')">
        <template slot="link-text">
          Tin Tức
        </template>
        <sidenav-router-link to="/tin-tuc/them-tin-tuc" :exact="true">Thêm mới</sidenav-router-link>
        <sidenav-router-link to="/tin-tuc" :exact="true">Danh sách</sidenav-router-link>
        <sidenav-router-link to="/tin-tuc/danh-muc" :exact="true">Danh mục</sidenav-router-link>
      </sidenav-menu>
      <sidenav-menu icon="ion ion-md-images" :active="isMenuActive('/thu-vien')" :open="isMenuOpen('/thu-vien')">
        <template slot="link-text">
          Thư viện
        </template>
        <sidenav-router-link to="/thu-vien/them-moi" :exact="true">Thêm mới</sidenav-router-link>
        <sidenav-router-link to="/thu-vien" :exact="true">Danh sách</sidenav-router-link>
      </sidenav-menu>
      <sidenav-menu icon="ion ion-md-checkmark" :active="isMenuActive('/banner')" :open="isMenuOpen('/banner')">
        <template slot="link-text">
          Banner
        </template>
        <sidenav-router-link to="/banner/them-moi" :exact="true">Thêm mới</sidenav-router-link>
        <sidenav-router-link to="/banner" :exact="true">Danh sách</sidenav-router-link>
      </sidenav-menu>
      <sidenav-router-link icon="ion ion-ios-notifications" to="/thong-bao" :exact="true">Thông báo</sidenav-router-link>
      <sidenav-divider class="mb-1" />
      <sidenav-header class="small font-weight-semibold">TÀI KHOẢN</sidenav-header>
      <sidenav-router-link icon="ion ion-ios-settings" to="/cai-dat" :exact="true">Cài đặt</sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-lock" to="/doi-mat-khau" :exact="true">Đổi mật khẩu</sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-log-out" to="/logout">Đăng xuất</sidenav-router-link>
    </div>
  </sidenav>
</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@/vendor/libs/sidenav'

export default {
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },

  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    }
  }
}
</script>
