import ApiService from '@/services/api.service'

const getUsername = () => (
  new Promise(resolve => {
    ApiService.get('api/account/username').then(({ data }) => {
      if (data.status === true) {
        resolve({ status: true, username: data.username, message: 'success' })
      } else {
        resolve({ status: false, message: data.message, errors: data.errors })
      }
    }).catch(({ response }) => { // client error handle
      console.log(response)
      resolve({ status: false, message: 'Error', errors: [response.statusText], statusCode: response.status })
    })
  })
)

const lockAccounts = (data) => {
  return new Promise(resolve => {
    ApiService.post('api/account/lockAccounts', data).then(({ data }) => {
      if (data.status === true) {
        resolve({ status: true, succeedIds: data.succeedIds, failedIds: data.failedIds, message: 'success' })
      } else {
        resolve({ status: false, message: data.message, errors: data.errors })
      }
    }).catch(({ response }) => { // client error handle
      console.log(response)
      resolve({ status: false, message: 'Error', errors: [response.statusText], statusCode: response.status })
    })
  })
}

const lockAccountsByConditions = (data) => {
  return new Promise(resolve => {
    ApiService.post('api/account/lockAccountsByConditions', data).then(({ data }) => {
      if (data.status === true) {
        resolve({ status: true, succeedIds: data.succeedIds, failedIds: data.failedIds, message: 'success' })
      } else {
        resolve({ status: false, message: data.message, errors: data.errors })
      }
    }).catch(({ response }) => { // client error handle
      console.log(response)
      resolve({ status: false, message: 'Error', errors: [response.statusText], statusCode: response.status })
    })
  })
}

const unlockAccounts = (userIds) => {
  return new Promise(resolve => {
    ApiService.post('api/account/unlockAccounts', { userIds: userIds }).then(({ data }) => {
      if (data.status === true) {
        resolve({ status: true, succeedIds: data.succeedIds, failedIds: data.failedIds, message: 'success' })
      } else {
        resolve({ status: false, message: data.message, errors: data.errors })
      }
    }).catch(({ response }) => { // client error handle
      console.log(response)
      resolve({ status: false, message: 'Error', errors: [response.statusText], statusCode: response.status })
    })
  })
}

const unlockAccountsByConditions = (data) => {
  return new Promise(resolve => {
    ApiService.post('api/account/unlockAccountsByConditions', data).then(({ data }) => {
      if (data.status === true) {
        resolve({ status: true, succeedIds: data.succeedIds, failedIds: data.failedIds, message: 'success' })
      } else {
        resolve({ status: false, message: data.message, errors: data.errors })
      }
    }).catch(({ response }) => { // client error handle
      console.log(response)
      resolve({ status: false, message: 'Error', errors: [response.statusText], statusCode: response.status })
    })
  })
}

const checkAccountStatus = (userId) => {
  return new Promise(resolve => {
    ApiService.get('/api/account/checkAccountStatus', userId).then(({ data }) => {
      if (data.status === true) {
        resolve({ status: true, isLocked: data.isLocked, message: 'success' })
      } else {
        resolve({ status: false, message: data.message, errors: data.errors })
      }
    }).catch(({ response }) => { // client error handle
      console.log(response)
      resolve({ status: false, message: 'Error', errors: [response.statusText], statusCode: response.status })
    })
  })
}

const createAccount = (data) => {
  return new Promise(resolve => {
    ApiService.post('api/account/create', data).then(({ status }) => {
      if (status === 200) {
        resolve({ status: true, message: 'Account created' })
      }
    }).catch(({ response }) => { // client error handle
      console.log(response)
      resolve({ status: false, message: 'Error', errors: [response.statusText], statusCode: response.status })
    })
  })
}

const updateAccount = (data) => {
  return new Promise(resolve => {
    ApiService.post('api/account/update', data).then(({ status }) => {
      if (status === 200) {
        resolve({ status: true, message: 'Account updated' })
      }
    }).catch(({ response }) => { // client error handle
      console.log(response)
      resolve({ status: false, message: 'Error', errors: [response.statusText], statusCode: response.status })
    })
  })
}

const resetAccountPassword = (userId) => {
  return new Promise(resolve => {
    ApiService.post('api/account/resetAccountPassword', { id: userId }).then(({ data }) => {
      if (data.status === true) {
        resolve({ status: true, message: 'success' })
      } else {
        resolve({ status: false, message: data.message, errors: data.errors })
      }
    }).catch(({ response }) => { // client error handle
      console.log(response)
      resolve({ status: false, message: 'Error', errors: [response.statusText], statusCode: response.status })
    })
  })
}

export default {
  getUsername,
  lockAccounts,
  lockAccountsByConditions,
  unlockAccounts,
  unlockAccountsByConditions,
  checkAccountStatus,
  createAccount,
  updateAccount,
  resetAccountPassword
}
