const ACCESS_TOKEN_KEY = 'access_token'

export const getToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY)
}

export const saveToken = token => {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, token)
}

export const destroyToken = () => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY)
}

export default { getToken, saveToken, destroyToken }
