import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '@/pages/NotFound'

import globals from '@/globals'
import AuthService from '@/services/auth.service'
// import ApiService from '@/services/api.service'

// Layouts
import Layout2 from '@/layout/Layout2'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  base: '/',
  mode: 'hash',
  routes: [{
    path: '/',
    component: Layout2,
    children: [{
      path: '',
      component: () => import('@/pages/Dashboard')
    },
    {
      path: 'page-2',
      component: () => import('@/pages/Page2')
    },
    {
      path: 'ung-vien',
      component: () => import('@/pages/candidate/CandidateList')
    },
    {
      path: '/ung-vien/them-ung-vien',
      component: () => import('@/pages/candidate/AddCandidate')
    },
    {
      path: 'ung-vien/chi-tiet/:id',
      component: () => import('@/pages/candidate/CandidateDetails')
    }, {
      path: 'ung-vien/cap-nhat/:id',
      component: () => import('@/pages/candidate/CandidateEdit')
    }, {
      path: 'tin-tuyen-dung',
      component: () => import('@/pages/recruitment/List')
    }, {
      path: 'tin-tuyen-dung/tin-hap-dan',
      component: () => import('@/pages/recruitment/AttractiveList')
    }, {
      path: 'tin-tuyen-dung/tin-tot-nhat',
      component: () => import('@/pages/recruitment/BestList')
    }, {
      path: 'tin-tuyen-dung/chi-tiet/:id',
      component: () => import('@/pages/recruitment/Details')
    }, {
      path: 'tin-tuyen-dung/chinh-sua/:id',
      component: () => import('@/pages/recruitment/Edit')
    }, {
      path: 'tin-tuyen-dung/dang-tin/:id',
      component: () => import('@/pages/recruitment/Create')
    }, {
      path: 'nha-tuyen-dung',
      component: () => import('@/pages/recruiter/List')
    }, {
      path: 'nha-tuyen-dung-noi-bat',
      component: () => import('@/pages/recruiter/FeaturedList')
    }, {
      path: 'nha-tuyen-dung/chi-tiet/:id',
      component: () => import('@/pages/recruiter/Details')
    }, {
      path: 'tin-tuc',
      component: () => import('@/pages/news/List')
    }, {
      path: 'tin-tuc/them-tin-tuc',
      component: () => import('@/pages/news/Created')
    }, {
      path: 'tin-tuc/chi-tiet/:id',
      component: () => import('@/pages/news/Edit')
    }, {
      path: 'tin-tuc/danh-muc',
      component: () => import('@/pages/news/Category')
    }, {
      path: 'thu-vien',
      component: () => import('@/pages/gallery/List')
    }, {
      path: 'thu-vien/them-moi',
      component: () => import('@/pages/gallery/Created')
    }, {
      path: 'thu-vien/chi-tiet/:id',
      component: () => import('@/pages/gallery/Edit')
    }, {
      path: 'banner',
      component: () => import('@/pages/banner/List')
    }, {
      path: 'banner/them-moi',
      component: () => import('@/pages/banner/Created')
    }, {
      path: 'banner/chi-tiet/:id',
      component: () => import('@/pages/banner/Edit')
    }, {
      path: 'doi-mat-khau',
      component: () => import('@/pages/ChangePassword')
    }, {
      path: 'cai-dat',
      component: () => import('@/pages/Setting')
    }, {
      path: 'thong-bao',
      component: () => import('@/pages/notification/Notification')
    }]
  },
  {
    path: '/login',
    component: () => import('@/pages/Login')
  }, {
    path: '/logout',
    component: () => import('@/pages/Logout')
  }, {
    // 404 Not Found page
    path: '*',
    component: NotFound
  }]
})

router.afterEach(() => {
  // Remove initial splash screen
  const splashScreen = document.querySelector('.app-splash-screen')
  if (splashScreen) {
    splashScreen.style.opacity = 0
    setTimeout(() => splashScreen && splashScreen.parentNode.removeChild(splashScreen), 300)
  }

  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  if (to.path !== '/login') {
    // Ensure we checked auth before each page load.
    AuthService.verify().then(response => {
      if (response.status === true) {
        setTimeout(() => {
          next()
        }, 10)
      } else {
        setTimeout(() => {
          next({ path: '/login', query: { returnUrl: to.path === '/logout' ? '' : to.path } })
        }, 10)
      }
    })
  } else {
    AuthService.verify().then(response => {
      if (response.status === true) {
        if (to.query.returnUrl === '' || to.query.returnUrl === undefined) {
          setTimeout(() => {
            next({ path: '/' })
          }, 10)
        } else {
          setTimeout(() => {
            next({ path: to.query.returnUrl })
          }, 10)
        }
      } else {
        setTimeout(() => {
          next()
        }, 10)
      }
    })
  }
})

// router.beforeEach((to, from, next) => {
//   // Set loading state
//   document.body.classList.add('app-loading')

//   // Add tiny timeout to finish page transition
//   setTimeout(() => next(), 10)
// })

export default router
